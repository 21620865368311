var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "accordion",
      class: { "accordion--open": _vm.open, "accordion--close": !_vm.open }
    },
    [
      _c(
        "p",
        {
          staticClass: "accordion__header",
          on: { click: _vm.accordionToggle }
        },
        [_vm._t("header")],
        2
      ),
      _vm._v(" "),
      _c(
        "transition",
        {
          attrs: { name: "slide" },
          on: {
            "before-enter": _vm.beforeEnter,
            enter: _vm.enter,
            "before-leave": _vm.beforeLeave,
            leave: _vm.leave
          }
        },
        [
          _vm.open
            ? _c(
                "div",
                { staticClass: "accordion__content" },
                [_vm._t("content")],
                2
              )
            : _vm._e()
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }