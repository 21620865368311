<template>
  <div class="accordion" :class="{'accordion--open': open, 'accordion--close': !open}" v-cloak>
    <p class="accordion__header" @click="accordionToggle">
      <slot name="header"></slot>
    </p>
    <transition
      name="slide"
      @before-enter="beforeEnter"
      @enter="enter"
      @before-leave="beforeLeave"
      @leave="leave"
    >
      <div class="accordion__content" v-if="open">
        <slot name="content"></slot>
      </div>
    </transition>
  </div>
</template>
<script>
  export default {
    name: 'accordion',
    props: {
      opend: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return{
        open: false
      }
    },
    methods: {
      alreadyOpenCheck(opend) {
        if(opend){
          this.open = true;
        }
      },
      accordionToggle() {
        this.open = !this.open;
      },
      beforeEnter(el) {
        el.style.height = '0';
      },
      enter(el) {
        el.style.height = el.scrollHeight + 'px';
      },
      beforeLeave(el) {
        el.style.height = el.scrollHeight + 'px';
      },
      leave(el) {
        el.style.height = '0';
      },
    },
    mounted() {
      this.alreadyOpenCheck(this.opend);
    }
  };
</script>
