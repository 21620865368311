<template>
  <div>
    <CoolLightBox
      :items="items"
      :index="index"
      :slideshow="false"
      @close="index = null; toggleClass()">
    </CoolLightBox>
    <div class="productImages">
      <!-- <div
        v-for="(image, imageIndex) in items"
        :class="{'productImages__first-image': !imageIndex, 'productImages__other-images': imageIndex}"
        :key="imageIndex"
        @click="index = imageIndex"
        :style="{ backgroundImage: 'url(' + image + ')' }"
      ></div> -->
      <div
        class="productImages__first-image"
        key="0"
        @click="index = 0; toggleClass()"
        :style="{ backgroundImage: 'url(' + items[0] + ')' }"
      ></div>
      <ul class="productImages__other-images">
        <li
          v-for="(image, imageIndex) in otherImages()"
          :key="imageIndex + 1"
          @click="index = imageIndex + 1; toggleClass()"
          :style="{ backgroundImage: 'url(' + image + ')' }"
        ></li>
      </ul>
    </div>
  </div>
</template>
<script>
  import CoolLightBox from '../vue-cool-lightbox-fix';
  export default {
    components: {
      CoolLightBox,
    },
    props: ['items'],
    data() {
      return{
        index: null,
        active: false
      }
    },
    // mounted: function() {
    //   this.items.filter(function(value, index) {
    //     const img = new Image();
    //     img.onload = function() {
    //       console.log(img.naturalWidth);
    //       console.log(img.naturalHeight);
    //     }
    //     img.src = value;
    //   });
    // },
    methods: {
      otherImages() {
        var otherImages = this.items.filter(function(value, index) {
          return index > 0;
        });
        // console.log(otherImages);
        return otherImages;
      },
      toggleClass() {
        this.active = !this.active;
        if(this.active){
          document.body.classList.add('js-cool-lightbox-active');
        }else{
          document.body.classList.remove('js-cool-lightbox-active');
        }
      }
    }
  }
</script>
