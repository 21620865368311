var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("CoolLightBox", {
        attrs: { items: _vm.items, index: _vm.index, slideshow: false },
        on: {
          close: function($event) {
            _vm.index = null
            _vm.toggleClass()
          }
        }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "productImages" }, [
        _c("div", {
          key: "0",
          staticClass: "productImages__first-image",
          style: { backgroundImage: "url(" + _vm.items[0] + ")" },
          on: {
            click: function($event) {
              _vm.index = 0
              _vm.toggleClass()
            }
          }
        }),
        _vm._v(" "),
        _c(
          "ul",
          { staticClass: "productImages__other-images" },
          _vm._l(_vm.otherImages(), function(image, imageIndex) {
            return _c("li", {
              key: imageIndex + 1,
              style: { backgroundImage: "url(" + image + ")" },
              on: {
                click: function($event) {
                  _vm.index = imageIndex + 1
                  _vm.toggleClass()
                }
              }
            })
          }),
          0
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }