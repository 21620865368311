<template>
  <div class="abcd">
    <p>vue component</p>
    <p>{{text}}</p>
  </div>
</template>
<script>
  export default {
    name: 'test',
    props: ['text'],
  };
</script>
