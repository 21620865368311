
import './sass/style.scss';

import Vue from 'vue';
import Test from './js/components/Test';
import Accordion from './js/components/Accordion';
import LightBox from './js/components/LightBox';

// コンポーネント
Vue.component('test', Test);
Vue.component('accordion', Accordion);
Vue.component('light-box', LightBox);

// カスタムディレクティブ
Vue.directive('animation', {
  bind: function(el, binding) {
    // window.scrollTo(0, window.pageYOffset + 1);
    // window.scrollTo(0, window.pageYOffset - 1);
    let f = function (evt) {
      if (binding.value(evt, el)) {
        window.removeEventListener('load', f);
      }
    }
    window.addEventListener('load', f);
  },
  inserted: function (el, binding, vnode) {
    // console.log(el);
    // console.log(binding);

    let f = function (evt) {
      if (binding.value(evt, el)) {
        window.removeEventListener('scroll', f);
      }
    }
    window.addEventListener('scroll', f);
  }
});
Vue.directive('focus', {
  inserted: function(el) {
    el.focus();
  }
});

const app = new Vue({
  el: '#app',
  data: {
    w_innerWidth: '',
    navActive: false,
    navExpand: false,
    breakPoint: 767,
    breakPoint2: 414,
    animationEl: []
  },
  mounted: function() {
    // リサイズイベント
    window.addEventListener('resize', this.handleResize);

    // カラーミーのテンプレートではviewportをjsで設定しており、mountedの時点でinnerWidthを正しく取得できないため
    const self = this;
    let count = 0;

    const timer1 = setInterval(function(){
      // console.log(self.breakPoint);
      // console.log(window.innerWidth);

      if(self.breakPoint >= window.innerWidth || count >= 29){
        self.handleResize();
        clearInterval(timer1);
      }

      count++;
    }, 100);

    // スクロールイベント
    // window.addEventListener('scroll', this.handleScroll);

    //
    // this.getAnimationElements();

    // const el =  document.querySelector('.keyvisual');
    // el.classList.add('animated', 'bounceInLeft');
  },
  beforeDestroy: function() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    navToggle: function() {
      this.navExpand = !this.navExpand;
    },
    navToggleBodyClass: function(navExpand) {
      if(navExpand && this.breakPoint >= window.innerWidth) {
        document.body.classList.add('js-nav-expanded');
      } else {
        document.body.classList.remove('js-nav-expanded');
      }
    },
    navStatusSet: function(w_width) {
      if(this.breakPoint < w_width){
        this.navActive = false;
        this.navExpand = false;
      } else {
        this.navActive = true;
      }
    },
    getAnimationElements: function() {
      this.animationEl = document.querySelectorAll('.js-animation');
    },
    elOffsetTop: function(el) {
      const clientRect = el.getBoundingClientRect();
      return clientRect.y;
    },
    handleResize: function() {
      this.w_innerWidth = window.innerWidth;
      this.navStatusSet(window.innerWidth);
    },
    // handleScroll: function() {
    //   // console.log(window.pageYOffset);
    //   // console.log(window.innerHeight);
    //
    //   for(let i = 0; i < this.animationEl.length; i++) {
    //     // 要素の相対位置
    //     const elPos = this.elOffsetTop(this.animationEl[i]);
    //
    //     // if (elPos < (window.innerHeight - 200)){
    //     //   if(i === 0){
    //     //     this.animationEl[i].classList.add('animated', 'fadeIn');
    //     //   } else {
    //     //     this.animationEl[i].classList.add('animated', 'fadeInUp');
    //     //   }
    //     // }
    //   }
    // }
    inView: function (evt, el) {
      const elPos = this.elOffsetTop(el);
      const data = el.getAttribute('data-effects') || "fadeIn";
      const effects = data.split(' ');

      if(elPos < (window.innerHeight - 200)) {
        el.classList.add('animated');

        for(let i = 0; i < effects.length; i++) {
          el.classList.add(effects[i]);
        }
      }
      return elPos < (window.innerHeight - 200);
    }
  },
  watch: {
    navExpand: function(navExpand) {
      this.navToggleBodyClass(navExpand);
    }
  }
});
